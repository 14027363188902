import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from '../db.service';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private service: DbService,
    private config: ConfigService
  ) { }
  public content;
  private id;

  ngOnInit() {
    window.scroll(0, 0);
    const machineName = this.route.snapshot.paramMap.get('machine_name');
    this.service.getEventDetail(machineName)
    .subscribe(data => {
      this.content = data;

      let title = this.content.content.title;
      title = title.replace('<small><sup>&#174;</sup></small>', '�');
      title = title.replace('<small><sup>&#8482;</sup></small>', '�');
      this.config.empty();
      this.config.addTitle(title);
      this.config.addTitle('events');
      this.config.outputTitle();

    });
  }

}
