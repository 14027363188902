import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { DbService } from '../db.service';
import { ConfigService } from '../config.service';

import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public siteKey: string = '6LcUkL0lAAAAAAEoxLWqn9SaNlhYBoDB-L84gWi1';
  public recaptchaResponse: string;

  constructor(
    private route: ActivatedRoute,
    private service: DbService,
    private config: ConfigService
  ) {}
  private result;
  public content;
  private id;
  public sendEnquirySuccess = false;

  ngOnInit() {
    this.config.setTitle('contact us');
    window.scroll(0, 0);
    this.service.getContactUs()
      .subscribe(data => {
        this.content = data;
      });

/*     $('#enquiry-form').on('submit', function() {
      const data = {};
      const formData = new FormData(this);
      formData.forEach((value, key) => {
        data[key] = value;
      });
      $.post('/biozone/data/sendEmail.php', data, result => {
        alert(result);
        $('#enquiry-form').get(0).reset();
      });
      return false;
    }); */
  }

  onSubmit(f) {
    let attr;
    const value = f.value;
    const formData = new FormData();

    if (this.recaptchaResponse) {
      // tslint:disable-next-line:forin
      for ( attr in value) {
        formData.append(attr, value[attr]);
      }
      this.service.sendEmail(formData).subscribe(() => {
        f.resetForm();
        this.sendEnquirySuccess = true;
      });
    } else {
      alert('The captcha is missing or has expired. Please verify that you are not a robot by completing the captcha.');
    }
  }

  onCaptchaResolved(response: string) {
    this.recaptchaResponse = response;
    // Handle reCAPTCHA verification here
  }

  onCaptchaExpired() {
    this.recaptchaResponse = null;
  }

}
