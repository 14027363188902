import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { DbService } from '../db.service';
import { LoadingService } from '../loading.service';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('homeVideoSrc') videoElement: ElementRef;

  public swiperConfig;
  public banners;
  public services;
  public applications;
  public studies;
  public temp;

  public showVideo : boolean;

  constructor(
    private service: DbService,
    private loading: LoadingService,
    private config: ConfigService
  ) {
  }

  showWelcomeVideo(): boolean {
    const welcomeSession = this.getCookie('welcomevideo');
    
    if (welcomeSession !== null && welcomeSession !== undefined && welcomeSession !== '') {
      return false;
    } else {
      const welcomeSessionValue = new Date().getTime().toString();// your code to generate a new session value
      this.setCookie('welcomevideo', welcomeSessionValue);
      return true;
    }
  }

  private getCookie(name: string): string {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  private setCookie(name: string, value: string): void {
    const path = '; path=/';
    document.cookie = name + '=' + value + path;
  }

  ngAfterViewInit() {
    $(window).resize(resizeSetHeight);
    resizeSetHeight();
    function resizeSetHeight() {
      const height = $('.ss-top img').height();
      const top = height / 2;
      $('.swiper-button-prev').css({
        top
      });
      $('.swiper-button-next').css({
        top
      });
    }

    const video = this.videoElement.nativeElement as HTMLVideoElement;
    video.play();
  }

  ngOnInit() {
    // window.scroll(0, 0);
    this.config.setTitle('');
    this.loading.show();

    this.showVideo = this.showWelcomeVideo();

    this.service.getHomeBannerList()
      .subscribe( data => {
        this.temp = data;

        for (let i = 0, max = this.temp.length; i < max; i++) {
          const cur = this.temp[i];
          const src = '/upload/' + cur.img;
          const img = new Image();
          img.onload = () => {
            if (i === max - 1) {
              setTimeout(() => this.loading.hide(), 400);
              this.banners = data;
            }
          };
          img.src = src;
        }

        $('body').delegate('.detail-btn-trigger', 'click', function() {
          const $bottom = $('.ss-bottom');
          const $this = $(this);
          if ($bottom.hasClass('show')) {
            $this.removeClass('open');
            $bottom.removeClass('show').slideUp(600);
          } else {
            $this.addClass('open');
            $bottom.addClass('show').slideDown(600);
          }
        });
      });

      $(document).on('click', '.home-video .overlay', function() {
        $('.home-video').remove();
      });

    this.service.getHomeServices().
      subscribe( data => {
        this.services = data;
      });

    this.service.getHomeApplications().
      subscribe( data => {
        this.applications = data;
      });

    this.service.getHomeCaseStudies().
      subscribe( data => {
        this.studies = data;
      });

    this.swiperConfig = {};
  }
}
