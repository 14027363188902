import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from '../db.service';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  private result;
  public content;
  private id;
  public page;
  public pageSize;
  private swiperConfig;
  public lang;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: DbService,
    private config: ConfigService
  ) {
      this.lang = this.router.url.split('/')[1];
  }

  ngOnInit() {
    this.config.setTitle('events');
    window.scroll(0, 0);
    this.service.getEventsList()
    .subscribe(data => {
      this.content = data;
    });
    this.page = 1;
    this.pageSize = 6;
  }
}
