import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService  {
  public component;

  constructor() {
    this.component = document.getElementById('component-loading');
  }

  show() {
    window.scroll(0, 0);
    this.component.classList.add('show');
  }

  hide() {
    this.component.classList.remove('show');
  }
}
