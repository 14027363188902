import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DbService } from '../db.service';

import * as $ from 'jquery';
import { TranslateService } from '../translate.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private $t;
  public menus;
  public lang;
  public url;
  public customSearch;
  public content;
  public gcsesearch: SafeHtml;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private service: DbService,
    private sanitizer: DomSanitizer
    ) {

      this.gcsesearch = this.sanitizer.bypassSecurityTrustHtml('<gcse:search></gcse:search>');
      const cx = '012885048907562440847:jn_ssgrdxfa';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);

      this.lang = this.router.url.split('/')[1];
      this.translate.use(this.lang);
      this.customSearch = 'http://biozonescientific.hk/' + this.lang + '/search';

  }

  ngOnInit() {
    this.$t = $('.navbar-toggler');
    $('body').delegate('.navbar-toggler', 'click', () => {
      this.$t.parent().addClass('show');
    });

    $('.overflow').on('click', () => {
      this.$t.parent().removeClass('show');
    });

    $('body').delegate('.nav-link', 'click', () => {
      this.$t.parent().removeClass('show');
    });

    /* $(window).on('resize', () => {
      this.$t.parent().removeClass('show');
    }); */

    this.activeRoute.parent.params.subscribe(params => {
      this.lang = params.lang;
    });

    this.service.getMenus()
    .subscribe( data => {
      this.menus = data;
    });
  }

  onClick(e) {
      const $this = $(e.currentTarget);
      const $next = $this.next();
      if ($this.hasClass('open')) {
        $this.removeClass('open');
        $next.slideUp();
      } else {
        $('.menu-trigger-btn.open').removeClass('open').next().slideUp();
        $this.addClass('open');
        $next.slideDown();
      }
  }

  onSubmit(f) {
    const q = f.value.query;
    this.router.navigate(['../search'], q);
  }

  setLang(lang: string) {
    const url = this.router.url.split('/');
    url[1] = lang;

    this.url = url.join('/');
    window.location.href = this.url;
    // this.url = this.url.substr(1, this.url.length);
    // this.translate.use(lang);
    // window.location.href = this.url;
/*     this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(
      () => this.router.navigateByUrl(this.url)
    ); */
  }
}
