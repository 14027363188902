import { Component, OnInit } from '@angular/core';
import { DbService } from '../db.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public menus;
  public year;
  public lang;

  constructor(
    private router: Router,
    private service: DbService) {
      this.lang = this.router.url.split('/')[1];

    }

  ngOnInit() {
    const date = new Date();
    this.year = date.getFullYear();
    this.service.getMenus()
    .subscribe( data => {
      this.menus = {};
      this.menus.technology = data[1];
      this.menus.applications = data[2];
      const len = data[3].child.length;
      const helf = Math.floor(len / 2);
      this.menus.products = {};
      this.menus.products.first = data[3].child.slice(0, helf);
      this.menus.products.second = data[3].child.slice(helf, len);
    });
  
  }

}
