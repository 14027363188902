import { Component, OnInit } from '@angular/core';
import { DbService } from '../db.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config.service';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-cookiepolicy',
  templateUrl: './cookiepolicy.component.html',
  styleUrls: ['./cookiepolicy.component.scss']
})
export class CookiePolicyComponent implements OnInit {
  public content;

  constructor(
    private service: DbService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private config: ConfigService
  ) {
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.config.setTitle('cookie policy');
  }

}
