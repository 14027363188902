import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from '../db.service';
import * as $ from 'jquery';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.scss']
})
export class TechnologyComponent implements OnInit {

  constructor(
    private service: DbService,
    private activeRoute: ActivatedRoute,
    private config: ConfigService
  ) {

  }
  private result;
  public data;
  private id;
  private swiperConfig;

  ngOnInit() {
    // const id = this.route.snapshot.paramMap.get('id');

    window.scroll(0, 0);

    this.activeRoute.params.subscribe(routeParams => {
      // this.loadUserDetail(routeParams.id);

      const machine_name = routeParams.machine_name;
      const lang = routeParams.lang;


      this.service.getTechnologyDetail(machine_name)
        .subscribe(data => {
          this.data = data;
          let title = this.data[0].title;
          title = title.replace('<small><sup>&#174;</sup></small>', '®');
          title = title.replace('<small><sup>&#8482;</sup></small>', '™');
          this.config.empty();
          this.config.addTitle(title);
          this.config.addTitle('technology');
          this.config.outputTitle();

          this.swiperConfig = {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
              994: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 1,
              }
            }
          };
        });
    });

    $('body').delegate('.spec-button-item', 'click', function() {
      const $this = $(this);
      const idx = $this.index();

      $('.spec-button-item').removeClass('active');
      $this.addClass('active');

      $('.spec-content-item').removeClass('active');
      $('.spec-content-item').eq(idx).addClass('active');

    });
  }

  onClick(e) {
    const $this = $(e.currentTarget);
    const $control = $this.find('.control');
    const $next = $this.next();
    if ($this.hasClass('open')) {
      $this.removeClass('open');
      $next.slideUp();
    } else {
      $this.addClass('open');
      $next.slideDown();
    }
  }
}