import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from './translate.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  public param;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router) {}

  getParam() {
    const url = this.router.url.split('/');
    const lang  = url[1];
    this.param = {
      params: {lang}
    };
  }

  getHomeBannerList() {
    this.getParam();
    return this.http.get('/biozone/data/home_banners.php', this.param);
  }

  getHomeServices() {
    this.getParam();
    return this.http.get('/biozone/data/home_services.php', this.param);
  }

  getHomeApplications() {
    this.getParam();
    // this.param.params.id = id;
    return this.http.get('/biozone/data/home_applications.php', this.param);
  }

  getHomeCaseStudies() {
    this.getParam();
    return this.http.get('/biozone/data/home_case_studies.php', this.param);
  }

  getAboutUs() {
    this.getParam();
    return this.http.get('/biozone/data/about_us.php', this.param);
  }

  getContactUs() {
    this.getParam();
    return this.http.get('/biozone/data/contact_us.php', this.param);
  }

  getEventsList() {
    this.getParam();
    return this.http.get('/biozone/data/events.php', this.param);
  }

  getEventDetail(machineName) {
    this.getParam();
    this.param.params.machine_name = machineName;
    return this.http.get('/biozone/data/event_detail.php', this.param);
  }

  getTechnologyDetail(machineName) {
    this.getParam();
    this.param.params.machine_name = machineName;
    return this.http.get('/biozone/data/technology.php', this.param);
  }

  getApplicationsDetail(machineName) {
    this.getParam();
    this.param.params.machine_name = machineName;
    return this.http.get('/biozone/data/applications.php', this.param);
  }

  getProductDetail(machineName) {
    this.getParam();
    this.param.params.machine_name = machineName;
    return this.http.get('/biozone/data/products.php', this.param);
  }

  getMenus() {
    this.getParam();
    return this.http.get('/biozone/data/menus.php', this.param);
  }

  getFooterMenus() {
    return this.http.get('biozone/data/footer_menus.php');
  }

  getGoogleSearch(param) {
    return this.http.get('https://www.googleapis.com/customsearch/v1?' + param);
  }

  sendEmail(param) {
    return this.http.post('biozone/data/sendEmail.php', param);
  }
}
