import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { TechnologyComponent } from './technology/technology.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ProductsComponent } from './products/products.component';
import { EventsComponent } from './events/events.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SearchComponent } from './search/search.component';
import { CookiePolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { RefreshComponent } from './refresh/refresh.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'en/home', pathMatch: 'full'},
  { path: 'refresh', component: RefreshComponent},
  { path: ':lang', children: [
    // { path: '', component: HomeComponent},
    { path: 'home', component: HomeComponent},
    { path: 'aboutUs', component: AboutComponent},
    { path: 'technology/:machine_name', component: TechnologyComponent},
    { path: 'applications/:machine_name', component: ApplicationsComponent, runGuardsAndResolvers: 'always'},
    { path: 'products/:machine_name', component: ProductsComponent},
    { path: 'events', component: EventsComponent},
    { path: 'event_detail/:machine_name', component: EventDetailComponent},
    { path: 'contact-us', component: ContactUsComponent},
    { path: 'about-us', component: AboutUsComponent},
    { path: 'search', component: SearchComponent},
    { path: 'cookiepolicy', component: CookiePolicyComponent},
    { path: 'privacypolicy', component: PrivacyPolicyComponent},

    { path: '**', component: PageNotFoundComponent}
  ]},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule {}
