import { Component, OnInit } from '@angular/core';
import { DbService } from '../db.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
  public content;
  public gcsesearch: SafeHtml;

  constructor(
    private service: DbService,
    private activateRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
    ) {
      this.activateRoute.params.subscribe(routeRarams => {

      /* const key = 'key=AIzaSyATM8RJLWVFWLwrDfGpThRodP75g6d199Y';
      const cx = '&cx=012885048907562440847:jn_ssgrdxfa';
      const query = '&q=' + routeRarams.q;
      const param = key + cx + query;

      this.service.getGoogleSearch(param).subscribe(data => {
        console.log(data);
        this.content = data;
      }); */


      this.gcsesearch = this.sanitizer.bypassSecurityTrustHtml('<gcse:search></gcse:search>');
      const cx = '012885048907562440847:jn_ssgrdxfa';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);

    });
  }

  ngOnInit() {
  }

}
