import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';

import { RecaptchaModule } from 'ng-recaptcha';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AboutComponent } from './about/about.component';
import { CookiePolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { TechnologyComponent } from './technology/technology.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ProductsComponent } from './products/products.component';
import { SafeHtmlPipe } from './SafeHTmlPipe';
import { EventsComponent } from './events/events.component';
import { EventDetailComponent } from './event-detail/event-detail.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { DbService } from './db.service';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { SearchComponent } from './search/search.component';
import { FormsModule } from '@angular/forms';

import { TranslateService } from './translate.service';
import { TranslatePipe } from './translate.pipe';
import { RefreshComponent } from './refresh/refresh.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingService } from './loading.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PreviewComponent } from './preview/preview.component';
import { ConfigService } from './config.service';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
};

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    TechnologyComponent,
    FooterComponent,
    HeaderComponent,
    ApplicationsComponent,
    ProductsComponent,
    SafeHtmlPipe,
    EventsComponent,
    EventDetailComponent,
    ContactUsComponent,
    AboutUsComponent,
    SearchComponent,
    TranslatePipe,
    RefreshComponent,
    LoadingComponent,
    PageNotFoundComponent,
    PreviewComponent
  ],
  imports: [
    HttpClientModule,
    RecaptchaModule,
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgxPageScrollCoreModule,
    FormsModule
  ],
  providers: [
    DbService,
    LoadingService,
    ConfigService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    TranslateService,
    TranslatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
