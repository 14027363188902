import { Component, OnInit } from '@angular/core';
import { DbService } from '../db.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config.service';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  public content;
  public lang;

  constructor(
    private router: Router,
    private service: DbService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private config: ConfigService
  ) {
    this.lang = this.router.url.split('/')[1];
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.config.setTitle('privacy policy');
  }

}
