import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from './translate.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private company = 'Biozone Scientific Limited';
  public titleArray = [];

  constructor(
    public titleService: Title,
    public translate: TranslateService,
    public route: ActivatedRoute,
    public router: Router
  ) {

  }

  public empty() {
    this.titleArray = [];
  }

  public addTitle(title: string) {
    this.titleArray.push(title);
  }

  public outputTitle() {
    this.translate.use(this.translate.lang).then((data) => {
      this.titleArray.forEach((part, idx, arr) => arr[idx] = this.translate.data[part] || part);
      const title = this.titleArray.join(' | ');
      this.titleService.setTitle(title + ' | ' + this.company);
    });
  }

  public setTitle(newTitle: string) {
    this.translate.use(this.translate.lang).then((data) => {
      const title = data[newTitle] || newTitle;
      if (title) {
        this.titleService.setTitle(title + ' | ' + this.company);
      } else {
        this.titleService.setTitle(this.company);
      }
    });
  }
}
