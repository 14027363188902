import { Component, OnInit } from '@angular/core';
import { DbService } from '../db.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config.service';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  public content;

  constructor(
    private service: DbService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private config: ConfigService
  ) {
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.config.setTitle('about us');
    this.service.getAboutUs().
      subscribe( data => {
        this.content = data;
    });
  }
  
}
